import { viewlioConfig } from '@viewlio/config/viewlioConfig'

export const datadogLogsScript = `
  if(typeof window !== undefined && window.location.origin && "${viewlioConfig.apiKeys.datadog.clientToken}") {
    (function(h,o,u,n,d) {
      h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
      d=o.createElement(u);d.async=1;d.src=n
      n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
    })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v4/datadog-logs.js','DD_LOGS')

    DD_LOGS.onReady(function() {
      DD_LOGS.init({
        clientToken: "${viewlioConfig.apiKeys.datadog.clientToken}",
        forwardErrorsToLogs: true,
        sampleRate: 100,
        site: 'datadoghq.com',
      })
    })
  }
`

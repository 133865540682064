import { viewlioConfig } from '@viewlio/config/viewlioConfig'
import { getIterableLocale } from '@viewlio/juulio-bridge/common/utils'
import { User, GeoLocation } from '@viewlio/types'
import cookie from 'js-cookie'
import mapKeys from 'lodash/mapKeys'

import { getConsent } from 'lib/cookiebot'
import { decamelizeKeys } from 'utils/caseConversion'

import {
  Integrations,
  integrationsByConsent,
  IntegrationsConfig,
} from './integrations'

const makeState = () => ({
  isReady: false,
  queue: [],
  runOnce: false,
})

// A factory method that builds wrappers for global.analytics methods. Pass
// in the name of the method (prop) we want to call on the underlying
// analytics object to create an analytics.js wrapper fn.
const wrapSegmentMethod = (prop, queueUntilLoaded = true) => (...args) => {
  // Initialize Segment only when configured
  if (!viewlioConfig.segment.enabled) return

  // if user has no track cookie set
  if (cookie.get(viewlioConfig.cookies.doNotTrackSegment)) return

  const state = getState()

  // If (for some unknown reason - but it does happen?!) analytics isn't
  // defined then gracefully abort.
  if (!global.analytics) return

  // If we are not ready, then queue the analytics call by appending it
  // to an array. We must wait until analytics has loaded and the anonymousId
  // has been correctly set.
  if (!state.isReady && queueUntilLoaded) {
    state.queue.push({ args, prop })
    return
  }

  // If we are ready, then simply pass the call onto analytics.
  global.analytics[prop](...args)
}

// A method that actually sends all previously queued/deferred analytics calls.
const processQueue = () => {
  const state = getState()
  for (const { prop, args } of state.queue) wrapSegmentMethod(prop)(...args)
  state.queue = [] // Clear the queue once we've processed all the calls.
}

// We need to explicitly memoize the state on the global object. Simply
// memorizing it using a local variable within this file is *not* sufficient.
// Standard behaviour is for an export to be computed when it is first
// imported, with all subsequent imports simply returning a reference to the
// initial computed value. Unfortunately there are some bits of the app where
// we re-compute the export and thus if we stored the state locally we'd have
// multiple copies of said state.
const getState = () => {
  if (!global._segmentState) global._segmentState = makeState()
  return global._segmentState
}

export type SegmentConfig = {
  anonymousId: string
  forgetIterableUsers: boolean
  geoLocation: GeoLocation
  key: string
  shouldUseCookiebot: boolean
  user?: User
}

export const initialize = async ({
  anonymousId,
  geoLocation,
  key,
  forgetIterableUsers,
  shouldUseCookiebot,
  user,
}: SegmentConfig): Promise<void> => {
  const state = getState()

  // If we've already run initialize once, make sure we don't run again by
  // aborting early.
  if (state.runOnce) return

  state.runOnce = true

  // If we can't find segment then we should just give up and return as theres
  // not much we can do.
  if (!global.analytics || !viewlioConfig.segment.enabled) return

  // User needs to consent to cookie categories before we can initialize segment
  const consent = shouldUseCookiebot && await getConsent()

  // If consent is resolved, then get integrations based on consent category
  // Else, we do not need consent and all integrations are enabled.
  const integrations: IntegrationsConfig = consent
    ? integrationsByConsent(consent)
    : { All: true }

  // If the store is configured to forget Iterable users, then we need to
  // disable Iterable for users who are not age-verified for marketing
  // or are locked.
  if (forgetIterableUsers) {
    const ageVerifiedForMarketing = user?.ageVerifiedForMarketing
    const permanentlyLocked = user?.permanentlyLocked

    if (!ageVerifiedForMarketing || permanentlyLocked) {
      integrations[Integrations.Iterable] = false
    }
  }

  // Extract the userId from gon. If it doesn't exist then the user must be a
  // guest and according to Segment docs we shouldn't specify a user id.
  const userId = user?.segmentId

  global.analytics.ready(() => {
    global.analytics._user.anonymousId(anonymousId)
    // All calls to analytics that have happened before this point will have
    // been queued on our end - as we need to wait until we correctly set
    // the anonymousId. If we didn't queue these calls, they would go out
    // with Segment's auto-created anonymousId which would make it look like
    // we had extra visitors.
    // Update the state object to prevent future calls from being queued,
    // and then finally dispatch all queued calls.
    state.isReady = true

    // Identify the user with some basic traits.
    const { timeZone } = geoLocation
    if (userId) {
      wrapSegmentMethod('identify')(userId, {
        email: user?.email,
        geo_location: {
          ...decamelizeKeys(geoLocation),
          time_zone: { id: timeZone },
        },
        locale: getIterableLocale(),
      })
    }

    // Inform Segment about the page we're currently looking at.
    // We must ensure that we append the Cookiebot consent preferences
    // to the payload of the page request. If GTM is a destination,
    // this page call will generate an event in the "Data Layer" called
    // "Page Loaded", which will contain the aforementioned preferences.
    // These preferences can then be consumed by GTM triggers which fire in
    // response to the "Page Loaded" event - allowing us to conditionally serve
    // tags from GTM in adherence with a user's preferences.
    wrapSegmentMethod('page')(null, {
      ...mapKeys(consent || {}, (_, k) => `${k}_preferences`),
    })

    processQueue()
  })

  // Tell Segment to explicitly load in all our whitelisted integrations.
  global.analytics.load(key, { integrations })
}

export const segmentTrack = (...args) => wrapSegmentMethod('track')(...args)

export const segmentPage = (...args) => wrapSegmentMethod('page')(...args)

import { StoreType } from '@viewlio/config/types'
import { viewlioConfig } from '@viewlio/config/viewlioConfig'
import {
  JuulStore,
  PersonalizedContent,
  Shopper,
  User,
} from '@viewlio/types'
import { Product } from '@viewlio/types/contentful'
import { ProductCatalogue } from '@viewlio/types/ecommerce'
import cookie from 'js-cookie'
import pMinDelay from 'p-min-delay'
import { create, StoreApi } from 'zustand'
import { combine } from 'zustand/middleware'

import { fetchFromApi, fetchCsrfToken } from 'lib/juulio/api'
import { getErrorMeta, getLoadingMeta, getSuccessMeta, initialLoadingMeta } from 'utils/api'
import { decamelizeKeys } from 'utils/caseConversion'

export type GlobalStore = {
  csrfToken: string | null
  juulioStore: JuulStore | null
  locale: string | null
  personalizedContent?: PersonalizedContent
  productCatalogue?: ProductCatalogue | null
  productExternalContent?: Record<string, Product> | null
  selectedState?: string | null
  shopper?: Shopper
  storeType?: StoreType
}

export const globalStoreInitialState = (): GlobalStore => ({
  csrfToken: null,
  juulioStore: null,
  locale: null,
  personalizedContent: {
    cornerBoxNotification: null,
    meta: initialLoadingMeta,
    personalizedEntries: {},
    slideInWidget: null,
  },
  productCatalogue: null,
  productExternalContent: null,
  selectedState: null,
  shopper: {
    meta: initialLoadingMeta,
    phoneVerifiedUntil: null,
    signedIn: false,
    suggestedStore: null,
  },
  storeType: StoreType.Consumer,
})

const globalStoreActions = (
  set: StoreApi<GlobalStore>['setState'],
  get: StoreApi<GlobalStore>['getState'],
) => ({
  fetchCsrfToken: async () => {
    try {
      const { data: { attributes: { csrfToken } } } = await fetchCsrfToken()

      set({
        csrfToken,
      })
    } catch {
      return
    }
  },

  fetchPersonalizedContent: async (params: {
    personalizedEntriesIds?: string[]
    url: string
  }) => {
    const { personalizedContent: prevPersonalizedContent } = get()

    try {
      const personalizedContent: Omit<PersonalizedContent, 'meta'> = await fetchFromApi({
        body: decamelizeKeys({ ...params }),
        method: 'POST',
        relativePath: '/api/v1/content/personalized',
      })

      set({
        personalizedContent: {
          ...personalizedContent,
          meta: getSuccessMeta(),
        },
      })

    } catch (error) {
      set({
        personalizedContent: {
          ...prevPersonalizedContent,
          meta: getErrorMeta(error),
        },
      })
    }
  },

  fetchShopper: async () => {
    const { shopper: prevShopper } = get()
    try {
      set({
        shopper: {
          ...prevShopper,
          meta: getLoadingMeta(),
        },
      })

      const shopper: Omit<Shopper, 'meta'> = await pMinDelay(
        fetchFromApi({ relativePath: '/api/v1/shopper' }),
        viewlioConfig.minLoadingDuration,
      )

      set({
        shopper: {
          ...shopper,
          meta: getSuccessMeta(),
        },
      })

    } catch (error) {
      set({
        shopper: {
          ...prevShopper,
          meta: getErrorMeta(error),
        },
      })
    }
  },
  getLimitType: () => get().juulioStore?.preferences.podBasedLimit ? 'pods' : 'packs',
  setSelectedState: () => set({
    selectedState: cookie.get(viewlioConfig.cookies.stateAbbr),
  }),
  updateUser: (user: Partial<User>) => {
    const { shopper: prevShopper } = get()

    set({
      shopper: {
        ...prevShopper,
        user: {
          ...prevShopper?.user,
          ...user,
        } as User,
      } as Shopper,
    })
  },
})

export const useGlobalStore = create(
  combine(
    globalStoreInitialState(),
    globalStoreActions,
  ),
)

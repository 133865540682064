import React from 'react'

import segment from '@segment/snippet'
import { viewlioConfig } from '@viewlio/config/viewlioConfig'
import Script from 'next/script'

import { useGlobalStore } from 'stores'

export const AppScripts: React.FC = () => {
  const { juulioStore } = useGlobalStore()
  const { cookiebotId, preferences } = juulioStore || {}
  const { showCookiebot } = preferences || {}

  return (
    <>
      {/* Cookiebot */}
      {showCookiebot && cookiebotId && (
        <>
          <Script
            id='Cookiebot'
            src='https://consent.cookiebot.com/uc.js'
            data-cbid={cookiebotId}
            type='text/javascript'
            async
          />
          <Script
            id='CookieDeclaration'
            src={`https://consent.cookiebot.com/${cookiebotId}/cd.js`}
            type='text/javascript'
            async
          />
        </>
      )}
      {/* End Cookiebot */}

      {/* AWS WAF ACL Integration */}
      {viewlioConfig.waf.integrationUrl && (
        <Script
          data-testid='waf-acl-integration'
          type='text/javascript'
          src={viewlioConfig.waf.integrationUrl}
        />
      )}
      {/* End AWS WAF ACL Integration */}

      {/* Segment */}
      <Script id='segment' dangerouslySetInnerHTML={
        {
          __html: segment.max({ load: false, page: false }),
        }
      } />
      {/* End Segment */}
    </>
  )
}


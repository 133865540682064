import { viewlioConfig } from '@viewlio/config/viewlioConfig'

type TLDMap = Record<string, string>

export const tldMap: TLDMap = Object.entries(viewlioConfig.stores)
  .reduce((acc, [store, { baseUrl }]) => {
    acc[store] = baseUrl.production
    return acc
  }, {})

export const domainFromStore = (store: string) =>
  tldMap[store] || ''

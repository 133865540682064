import { LoadingMeta } from '@viewlio/types'

import { initialLoadingMeta } from './initialLoadingMeta'

export const getErrorMeta = (error?: string): LoadingMeta => ({
  ...initialLoadingMeta,
  error: true,
  errorMessage: error,
  loaded: true,
})

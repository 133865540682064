import { NavigationEntry } from '@viewlio/types/contentful'

import { formatEntry } from 'lib/contentful/formatEntry'

const formatSubItems = item => {
  if (item.subItems) {
    return item.subItems.map(subItem => ({
      displayText: subItem.displayText,
      id: subItem.id,
      link: subItem.link,
      promotionalItems: [],
      subItems: formatSubItems(subItem),
    }))
  }

  return []
}

const parsePrimaryItems = entry => entry.primaryItems.map(
  (primaryItem) => ({
    displayText: primaryItem.displayText,
    id: primaryItem.id,
    link: primaryItem.link,
    linkEventName: primaryItem.linkEventName,
    promotionalItems: [],
    subItems: formatSubItems(primaryItem),
    textClass: primaryItem.meta?.includes('color-auto-ship')
      ? 'navigation__primary-item--color-auto-ship'
      : null,
  }),
)

const parseSecondaryItems = entry => entry.secondaryItems.map(
  (secondaryItem) => ({
    displayText: secondaryItem.displayText,
    id: secondaryItem.id,
    image: {
      description: secondaryItem.image?.description,
      url: secondaryItem.image?.url,
    },
    link: secondaryItem.link,
    linkEventName: secondaryItem.linkEventName,
    positionEnd: secondaryItem.meta?.includes('position-end'),
    promotionalItems: [],
    subItems: [],
  }),
)

const parseBottomItems = entry => entry.bottomItems?.map(
  ({ displayText, id, image, link, meta }) => ({
    displayText,
    id,
    image: {
      url: image?.url,
    },
    link,
    textClass: meta?.includes('color-auto-ship')
      ? 'navigation__primary-item--color-auto-ship'
      : null,
  }),
) ?? []

export const getProps = (entry: NavigationEntry) => {
  const formattedEntry = formatEntry(entry)

  return {
    bottomItems: parseBottomItems(formattedEntry),
    logo: {
      description: formattedEntry.logo.description,
      url: formattedEntry.logo.url,
    },
    primaryItems: parsePrimaryItems(formattedEntry),
    secondaryItems: parseSecondaryItems(formattedEntry),
  }
}

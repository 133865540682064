import React, { ComponentType, FC } from 'react'

import { ViewlioStore } from '@viewlio/config/viewlioConfig/stores'

import { useGlobalStore } from 'stores'

type OverrideRules<T> = Partial<
  Record<ViewlioStore, ComponentType<T> | false | 'default'>
>

export const withStoreCustomization = <T extends Record<string, unknown>>(
  overrideRules: OverrideRules<T>,
  BaseComponent: ComponentType<T> | null = null,
) : FC<T> => {
  const NewComp: FC<T> = ({ ...props }) => {
    const { juulioStore } = useGlobalStore()
    let Component = overrideRules[juulioStore.code] ?? 'default'

    if (Component === 'default') {
      Component = BaseComponent
    }

    return Component && <Component {...props} />
  }
  NewComp.displayName = `${BaseComponent?.displayName}WithStoreCustomization`
  return NewComp
}

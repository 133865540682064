import { StoreType } from '../types'
import {
  contentfulAccessTokens,
  ViewlioStoreConfig,
  ViewlioCountryCode,
} from '../viewlioConfig/stores'

export type ViewlioBusinessStore =
  | 'juul-wholesale-us'
  | 'juul-wholesale-uk'
  | 'juul-wholesale-ca'
  | 'juul-business-uk'

export type ViewlioBusinessStoreConfig = Omit<ViewlioStoreConfig, 'ageGate' | 'storeLocator' | 'viewlioOrigins'>

const getDevelopmentBaseUrl = (
  countryCode: ViewlioCountryCode,
  type: StoreType | null = null,
) => {
  const baseUrl = new URL('http://dev.juul.com:3001')
  baseUrl.host = (type ? `${type}-` : '') + `${countryCode}.${baseUrl.host}`

  return baseUrl.toString()
}

export const stores: Record<
  ViewlioBusinessStore, ViewlioBusinessStoreConfig
> = {
  'juul-business-uk': {
    baseUrl: {
      ci: 'https://partner-uk.stage.juul.com',
      development: getDevelopmentBaseUrl(ViewlioCountryCode['uk'], StoreType.Partner),
      preprod: 'https://partner-uk.preprod.juul.com',
      preview: 'https://partner-uk.stage.juul.com',
      production: 'https://partner.juul.co.uk',
      qa1: 'https://partner-uk.qa1.juul-dev.com',
      qa2: 'https://partner-uk.qa2.juul-dev.com',
      qa3: 'https://partner-uk.qa3.juul-dev.com',
      qa4: 'https://partner-uk.qa4.juul-dev.com',
      qa5: 'https://partner-uk.qa5.juul-dev.com',
      staging: 'https://partner-uk.stage.juul.com',
    },
    contentful: {
      ...contentfulAccessTokens['juul-uk'],
      spaceId: 'ho2j2jvaj4rt',
    },
    countryCode: 'GB',
    defaultBaseUrl: 'http://partner-uk.dev.juul.com',
    iso: 'uk',
    label: 'United Kingdom Partner',
    locales: [
      'en-GB',
    ],
    type: StoreType.Partner,
  },
  'juul-wholesale-ca': {
    baseUrl: {
      ci: 'https://wholesale-ca.stage.juul.com',
      development: getDevelopmentBaseUrl(ViewlioCountryCode['ca'], StoreType.Wholesale),
      preprod: 'https://wholesale-ca.preprod.juul.com',
      preview: 'https://wholesale-ca.stage.juul.com',
      production: 'https://wholesale.juul.ca',
      qa1: 'https://wholesale-ca.qa1.juul-dev.com',
      qa2: 'https://wholesale-ca.qa2.juul-dev.com',
      qa3: 'https://wholesale-ca.qa3.juul-dev.com',
      qa4: 'https://wholesale-ca.qa4.juul-dev.com',
      qa5: 'https://wholesale-ca.qa5.juul-dev.com',
      staging: 'https://wholesale-ca.stage.juul.com',
    },
    contentful: {
      ...contentfulAccessTokens['juul-ca'],
      spaceId: 'vyk520m9px5z',
    },
    countryCode: 'CA',
    defaultBaseUrl: 'http://wholesale-ca.dev.juul.com',
    iso: 'ca',
    label: 'Canada',
    locales: [
      'en-CA',
      'fr-CA',
    ],
    type: StoreType.Wholesale,
  },
  'juul-wholesale-uk': {
    baseUrl: {
      ci: 'https://wholesale-uk.stage.juul.com',
      development: getDevelopmentBaseUrl(ViewlioCountryCode['uk'], StoreType.Wholesale),
      preprod: 'https://wholesale-uk.preprod.juul.com',
      preview: 'https://wholesale-uk.stage.juul.com',
      production: 'https://wholesale.juul.co.uk',
      qa1: 'https://wholesale-uk.qa1.juul-dev.com',
      qa2: 'https://wholesale-uk.qa2.juul-dev.com',
      qa3: 'https://wholesale-uk.qa3.juul-dev.com',
      qa4: 'https://wholesale-uk.qa4.juul-dev.com',
      qa5: 'https://wholesale-uk.qa5.juul-dev.com',
      staging: 'https://wholesale-uk.stage.juul.com',
    },
    contentful: {
      ...contentfulAccessTokens['juul-uk'],
      spaceId: 'ho2j2jvaj4rt',
    },
    countryCode: 'GB',
    defaultBaseUrl: 'http://wholesale-uk.dev.juul.com',
    iso: 'uk',
    label: 'United Kingdom Wholesale',
    locales: [
      'en-GB',
    ],
    type: StoreType.Wholesale,
  },
  'juul-wholesale-us': {
    baseUrl: {
      ci: 'https://wholesale-us.stage.juul.com',
      development: getDevelopmentBaseUrl(ViewlioCountryCode['us'], StoreType.Wholesale),
      preprod: 'https://wholesale-us.preprod.juul.com',
      preview: 'https://wholesale-us.stage.juul.com',
      production: 'https://wholesale.juul.com',
      qa1: 'https://wholesale-us.qa1.juul-dev.com',
      qa2: 'https://wholesale-us.qa2.juul-dev.com',
      qa3: 'https://wholesale-us.qa3.juul-dev.com',
      qa4: 'https://wholesale-us.qa4.juul-dev.com',
      qa5: 'https://wholesale-us.qa5.juul-dev.com',
      staging: 'https://wholesale-us.stage.juul.com',
    },
    contentful: {
      ...contentfulAccessTokens['juul-us'],
      spaceId: 'tc11z0kp0vll',
    },
    countryCode: 'US',
    defaultBaseUrl: 'http://wholesale-us.dev.juul.com',
    iso: 'us',
    label: 'United States Wholesale',
    locales: [
      'en-US',
    ],
    type: StoreType.Wholesale,
  },
}
